.about {
font-size: 16px;
    margin: auto;
    padding: 6rem 1rem 2rem 1rem;
    width: 100%;
    background: url('./images/abouteBackgroung.png') no-repeat center center/cover;

}

.about .container {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about .container img {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: url('./images/backDoublePhone.webp') no-repeat center center/cover;
    background-size: contain;
    background-origin: border-box;
    background-repeat: no-repeat;
}

.about h2 {
    color: #302F2F;
    text-align: start !important;
    font-size: 4rem;
    font-weight: 600;
    justify-self: start;
}

.about p {
    width:80%;
    font-size: 1.20rem;
    line-height: 20px;
    color: #302F2F;

}


.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;

}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}


@media screen and (max-width: 1300px) {

    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 50rem;
    }
    .about .container .col-2 {
        align-items: center;
    }
    .about p {
        width:100%;

    
    }
    

}

@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 80vw;
    }

    .about .container .col-2 {
        padding: 0;
        align-items: center;
    }

    .about button {
        margin-bottom: 1rem;
    }
    .about p {
        width:100%;
        font-size: 0.9rem;

    
    }
    .about h2 {
     
        font-size: 3rem;
    
    }
    
    
}