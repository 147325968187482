.testimonials {
    width: 100%;
    color: #fff;
    padding: 7rem;

    position: relative;
}


.testimonials .container {
    margin: 4rem auto;
    display: flex;
    justify-content: space-around;


}

.testimonials .container .accordiant-container {
    width: 40%;


}

.testimonials .container .FAQ-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.testimonials .container .FAQ-container .logo-images {
    width: 7rem;
    height: auto;
    margin-top: 2rem;

}

.testimonials .container .FAQ-container .App-container {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 20px
}
.testimonials .container .FAQ-container .App-container .Apple {
    margin: 0px !important;
    width: 15vw;
}

.testimonials .container .FAQ-container .App-container .Google {
    margin: 0px !important;
    width: 15vw;

}


/* .testimonials .container .FAQ-container  .App-container img:first-child {
  margin-right: 5px;
} */
.testimonials h2 {
    font-size: 4rem;
    text-align: start;
    margin-bottom: 30px;
}

.testimonials .container .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 4rem 0;
}

.testimonials .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0, 0, 0, .2);
    padding: 1rem;
}




.testimonials .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.testimonials .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (max-width: 940px) {
    .testimonials {
        width: 100%;
        color: #fff;
        padding: 0.5rem;
        position: relative;
    }



    .testimonials .container {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }

    .testimonials .container .accordiant-container {
        width: 100%;
        margin: 2rem 0 0 0;

    }

    .testimonials .container .FAQ-container {
        width: 100%;
        flex-wrap: "wrap";
        align-items: center;
    }

    .testimonials .container .FAQ-container .logo-images {
        justify-self: center;
        align-self: center;
        width: 6rem;
    }

    f {

        width: 7rem;
    }

    .testimonials .container .FAQ-container p {

        font-size: 1rem;
    }

    .testimonials .container {
        width: 100%;
    }

    .testimonials .container .content {
        grid-template-columns: 1fr;
    }

    .testimonials .card {
        max-width: 100%;
        margin: 1rem;
    }


    .testimonials .container .FAQ-container .App-container {
        width: 100%;
        height: auto;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;


    }

    .testimonials .container .FAQ-container .App-container .Apple {
        margin-left: 0px !important;
        width:30vw
    }
    .testimonials .container .FAQ-container .App-container .Google {
        margin-left: 0px !important;
        width:31vw
    }

    .testimonials .container .accordiant-container .AccordionSummary {
        font-size: 1rem;


    }
    .testimonials h2 {
        font-size: 3rem;
      
    }


}