.hero {
    color: #fff;
    height: 130rem;
    width: 100%;
    position: relative;
    background: url('./images/BG23.svg') no-repeat center center/cover;
}

.hero:before {
    content: '';
    background: linear-gradient(110.176deg, #083FA1 0%, #28B9D6 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero .content .top-content {
    display: flex;
    justify-content: center;
    padding: 7rem 0rem 1rem 0rem;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.hero .content .right-content {
    flex-direction: column;
    width: 40%;
    height: auto !important;
    display: flex;
    align-items: center;
    background: url('./images/circle.png') no-repeat center center/cover;
    display: flex;
    background-size: 90% auto;
}

.hero .content .right-content .phone-container {
    position: relative;
    width: 49%;
    /* Width of the phone image */
    height: 39vw !important;
    /* Height of the phone image */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    overflow: hidden;
    /* Ensures the video doesn't overflow the container */
}

.hero .content .right-content .background-video {
    position: relative;
    top: 3%;
    /* Adjust this to fit the screen's top edge */
    left: 0%;
    /* Adjust this to fit the screen's left edge */
    width: 92%;
    /* Adjust this to fit the screen's width */
    height: 36vw;
    /* Adjust this to fit the screen's height */
    z-index: 68;
    border-bottom-left-radius: 60px !important;
    /* Match this to the phone screen’s corners if rounded */
    border-bottom-right-radius: 60px !important
}

.hero .content .right-content .phone-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    /* Ensures the phone image doesn’t block the video */
}

.hero .content .left-content {
    flex-direction: column;
    width: 40%;
}

.hero .content .left-content p:first-child {
    margin-bottom: 1rem;
    font-size: 4vw;
    font-weight: 700;
}

.hero .content .text-logo-container .App-container a .Appel {
    margin-top: 10px;
    width: 15vw !important;
    height: auto;
}

.hero .content .text-logo-container .App-container a .Google {
    margin-top: 10px;
    width: 15vw !important;
    height: auto;
}

.hero .content .left-content p:nth-child(2) {
    margin-bottom: 1rem;
    font-size: 3vw;
    font-weight: 700;
}

.hero .content .left-content p:nth-child(3) {
    margin-bottom: 1rem;
    font-size: .9vw;
    font-weight: 700;
}

.hero .content .text-logo-container {
    margin-top: 5rem;
    width: 79%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    height: 250px;
    padding: 3rem;
    border-radius: 20px;
    align-items: center;
}

.hero .content .text-logo-container p:first-child {
    color: #083FA1;
    font-size: 1.7vw;
    font-weight: 700;
}

.hero .content .swiper-container {
    display: flex;
    flex-direction: row;
}

.hero .content .left-content p:nth-child(4) {
    font-size: .9vw;
}

.detail-photo {
    width: 15vw;
}

@media (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {

    /* iPad Pro portrait */
    .hero {
        height: 150vh !important;
        /* Ajustez selon les besoins */
    }
}

@media (min-width: 820px) and (max-width: 1180px) and (orientation: landscape) {

    /* iPad Air paysage */
    .hero {
        height: 240vh !important;
        /* Ajustez selon les besoins */
    }
}

@media (min-width: 820px) and (max-height: 1180px) and (orientation: portrait) {

    /* iPad Air portrait */
    .hero {
        height: 120vh !important;
        /* Ajustez selon les besoins */
    }
}


@media (min-width: 744px) and (max-width: 1133px) and (orientation: landscape) {

    /* iPad Mini paysage */
    .hero {
        height: 220vh !important;
        /* Ajustez selon les besoins */
    }
}

@media (min-width: 744px) and (max-height: 1133px) and (orientation: portrait) {

    /* iPad Mini portrait */
    .hero {
        height: 130vh !important;
        /* Ajustez selon les besoins */
    }
}


.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
}

@media screen and (max-width: 1300px) {

    .hero {
        height: 110rem !important;
    }

    .hero .content {
        width: 100%;
    }

    .hero .content .text-logo-container {
        height: 150px;
        padding: 1rem;

    }



    .detail-photo {
        width: 15vw;
    }

    .hero .content .text-logo-container img {
        width: 350px;
        height: 75px;
    }


    .hero .content .text-logo-container .App-container img {
        margin-top: 10px;
        width: 120px;
        height: auto;
    }

    .hero .content .text-logo-container .logo-container img {
        margin-top: 10px;
        width: 10vw;
        height: auto;
    }



}






@media screen and (max-width:940px) {
    .hero {
        height: 112rem !important;
        width: auto;
    }

    .hero .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero .content .top-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 70px;
        padding: 7rem 1rem 0;
        flex-wrap: wrap;
    }





    .hero .content .right-content {
        width: 50%;
        height: auto;
    }
    .hero .content .left-content {
        width: 100%;
        height: auto;
    }

    .hero .content .right-content .phone-container {
        position: relative;
        width: 70%;
        /* Width of the phone image */
        /* Height of the phone image */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        overflow: hidden;
        /* Ensures the video doesn't overflow the container */
        height: 60vw !important;
    }

    .hero .content .right-content .background-video {

        position: relative;
        top: 0%;
        /* Adjust this to fit the screen's top edge */
        left: 0%;
        /* Adjust this to fit the screen's left edge */
        width: 90%;
        /* Adjust this to fit the screen's width */
        height: 60vw;
        /* Adjust this to fit the screen's height */
        z-index: 68;

    }

    .hero .content .right-content .phone-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        /* Ensures the phone image doesn’t block the video */
    }


    .hero .content .left-content p:nth-child(1) {
        font-size: 1.5rem;
    }

    .hero .content .left-content p:nth-child(2) {
        font-size: 1.2rem;
    }

    .hero .content .left-content p:nth-child(3) {
        font-size: 0.6rem;
    }

    .hero .content .left-content p:nth-child(4) {
        font-size: 0.6rem;
    }

    .hero .content .right-content img {
        height: 300px;
        width: auto;
    }

    .hero .content .text-logo-container {
        width: 90%;
        height: 250px;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }

    .hero .content .text-logo-container p:first-child {
        font-size: 1rem;
        font-weight: 700;
    }

    .hero .content .text-logo-container .logo-container img {
        margin-top: 10px;
        width: 15vw;
        height: auto;
    }

    .hero .content .text-logo-container .App-container a .Appel {
        margin-top: 10px;
        width: 20vw !important;
        height: auto;
    }

    .hero .content .text-logo-container .App-container a .Google {
        margin-top: 10px;
        width: 20vw !important;
        height: auto;
    }


}

@media screen and (max-width:376px) {
    .hero {
        height: 100rem;
        width: auto;
    }
}

.swiper_container {
    margin-top: 100px;
    height: auto;
    width: 100% !important;
    /* Adjust height as needed */
    position: relative;
    z-index: 0 !important;
    padding: 0rem !important;

}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
}

.swiper-slide img {
    width: 22rem;
    /* Adjust width as needed */
    height: auto;
    border-radius: 2rem;
    object-fit: cover;
}

.slider-controler {
    margin-top: 2rem;
    color: green;
}

.slider-controler .swiper-button-prev,
.slider-controler .swiper-button-next {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: var(--white);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-prev ion-icon,
.slider-controler .swiper-button-next ion-icon {
    font-size: 2rem;
    color: #222224;
}

/*
.slider-controler .swiper-button-prev::after,
.slider-controler .swiper-button-next::after {
    content: '';
}
*/


.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(54, 86, 166, 0));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: rgb(0, 255, 8);
}

/* Adjustments for responsiveness */
@media (max-width: 940px) {
    .swiper_container {
        padding: 2rem;
        width: 100%;
        height: auto;
    }

    .swiper-slide {
        width: 100%;

    }

    .swiper-slide img {
        width: auto;
        height: 400px !important;
    }
}

@media (max-width: 940px) {
    .slider-controler .swiper-button-next {
        /* left: 70% !important; */
        transform: translateX(-70%) !important;
    }

    .slider-controler .swiper-button-prev {
        /* left: 30% !important; */
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 940px) {
    .slider-controler .swiper-button-next {
        /* left: 80% !important; */
        transform: translateX(-80%) !important;
    }

    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}