.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    transition: .3s ease-in;
    overflow: hidden;
    background: linear-gradient(110.176deg, #083FA1 0%, #28B9D6 100%);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1500px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #ffffff;
}

.header .navbar .home-logo {
    width: 120px;
    height: auto;
}

.header .nav-menu {
    display: flex;
    align-items: center;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a.active,
.header .nav-item a:hover,
.header .nav-item a:active {
    border-bottom: 1px solid #ffff;
}
.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 120px;
    }
    .header .navbar .lang-item {

        align-items: center;
        display: flex;
        justify-content: center;
        width:10%
    }

}
















