.demo {
    padding: 2rem 12.5rem;
    width: 100%;

}



.demo .container {
    background: url('./images/abouteBackgroung.png') no-repeat center center/cover;
    background-color: white;
    width: 100%;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;

}

.demo .container h2 {
    font-size: 4rem;
    font-weight: 500;
    margin-bottom: 1rem;

}

.demo .container p {
    font-size: 1rem;
    display: grid;
}

.input-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto) 1fr;
    gap: 16px;

    /* Add some space between inputs */

}

.button-send {
    margin-top: 20px;
    border: none;
    background: linear-gradient(108.697deg, #083FA1 0%, #28B9D6 100%);
    color: white;
    padding: 1rem;
    width: 70%;
    border-radius: 10px;
    box-shadow: 1px 3px 1px rgb(163, 157, 157);
    cursor: pointer;
    /* Smooth transition effect */
}

.button-send:hover {
    background: linear-gradient(108.697deg, #0050B3 0%, #1AD0EC 100%);
    /* Change to your desired hover colors */
}

.bottom-content {
    margin-top: 3rem;
    width: 100%;
    display: flex;
justify-content: space-between;}

.bottom-content p {
    color: white;
    font-size: 3rem ;
    font-weight: 700;
}

.demo .col-1 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.demo .col-1 p:nth-child(1) {
    font-size: 1.8rem;
    font-style: italic;
}

.demo .col-1 p:nth-child(2) {
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem 0;
}

.demo .col-1 p:nth-child(3) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}


.demo .col-2 {
    margin: auto;
}



.App-container img {
    margin-left: 20px;
    width: 14vw;
}

@media screen and (max-width: 940px) {

    .demo {
        padding: 0.5rem;
        width: 100%;
    }


    .demo .container {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .demo iframe {
        width: 100%;
        height: auto;
    }

    .demo .container h2 {
        font-size: 2rem;
      }
    
    .bottom-content p {
        color: white;
        font-size: 1.5rem ;
        font-weight: 700;
    }

     .bottom-content  {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 30px;
} 

.input-container {
    width: 100%;

 

    /* Add some space between inputs */

}
@media screen and (max-width: 450px)  {

    .input-container{
        display: flex;

        flex-wrap: wrap;

    }
}
.App-container  {
   align-self: center;


}
    .App-container img {
        width: 10rem;
        height: auto;
   
    }
}