.language-selector-container {
    margin-left: 30px;
}

.flags-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}



@media screen and (max-width:940px) {
    .flags-icon {
        width: 40px !important;
        height: auto !important;
        border-radius: 0 !important;
        object-fit: cover !important;
        margin-right: 10px !important;

    }
}