.footer {
    width: 100%;
    color: #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem;

}

.footer .container {
    padding: 0rem 12.5rem;

    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.footer .container .big-line{
    display: block;
    height: 8px;
    background-color: #979797;
    border-radius: 5px;
  
}

.footer .container .log-container
{
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}
.footer .container .last-container
{
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}

.footer .container .log-container img
{
    width: 10rem;
    height: auto;
}

.footer ul {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
 
}


.footer li {
    padding: 0.7rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}


@media screen and (max-width:940px) {

    .footer {
      
        padding: 3rem 0.5rem;
    }
    .footer .container {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items:center;
        text-align: center;
        padding: 0;
    }

    .footer .container ul  {
        max-width: 120rem;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items:center;
        text-align: center;
    }
}



