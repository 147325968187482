:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark:#e3d002;
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
   font-family: "Poppins", sans-serif;

}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 4rem;
  height: 8px;
  background: linear-gradient(169.023deg, #B2EBF2 0%, #D1C4E9 100%);  border-radius: 5px;
}

.background-color {
width: 100%;
position: relative;
background-image: url('./components/images/Squared\ Loops.png'); /* Replace with your actual file path */
background-repeat: no-repeat; /* Ensure the background doesn't repeat */
background-size: cover; /* Cover the entire container */
}



.background-color:before {
  content: '';
  background: linear-gradient(110.176deg, #083FA1 0%, #28B9D6 100%);
  ;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

